@import "all_variables";

.table {
    thead {
        tr:first-child,
        th {
            background-color: $table-header-background-color;
            color: $table-header-color;
        }

        th,
        td {
            border: 1px solid $table-border-color;
            padding: 0.4rem 0.4rem 0.4rem 0.5rem;
        }

        th {
            .sortable {
                cursor: pointer;

                .fa-sort {
                    color: $bg-elements-color;
                }
            }
        }
    }
}

.table,
.table-sm {
    &.tbl-border-less {
        td,
        th {
            border: 1px solid transparent;
            padding-right: 5px;
        }
    }

    // paging
    .pagination-row {
        background-color: $table-header-background-color;

        th,
        td {
            border: 1px solid $table-border-color;
            padding: 0.4rem 0.4rem 0.4rem 0.5rem !important;
        }

        input {
            width: 40px;
            margin-right: 10px;
        }

        .btn {
            border: 1px solid $page-btn-border-color;
        }

        ul {
            margin-bottom: 0;
        }
    }
}

//filter-form
.filter-form {
    .no-radius {
        border-radius: 0;
        height: 100%;
    }

    .form-control {
        height: 100%;
        background-clip: inherit;

        &:focus {
            border-color: inherit;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
}

.ng-select {
    &.no-radius {
        .ng-select-container {
            @extend .no-radius;
        }
    }

    &.border-danger {
        .ng-select-container {
            border-color: $red !important;
            color: $red !important;
        }
    }

    &.ng-select-disabled {
        .ng-select-container {
            background-color: $disabled-color !important;
            cursor: not-allowed !important;

            input,
            span {
                cursor: not-allowed !important;
            }
        }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
        background: $primary;
        color: $white;
    }
}

.mapping {
    .ng-select .ng-select-container.ng-has-value {
        background: lightblue;
    }
}
