$primary: #089fdf;
$table-header-background-color: #e5e5e5;
// resize to standard Information Systems text size
$font-size-base: 0.825rem;
$table-header-color: #4f4f4f;
$bg-elements-color: #c7c7c7;
$disabled-color: #e9ecef;
$page-btn-border-color: #aaa;

// Fonts
$font-size-base: 0.75rem;
$font-family-base: arial, helvetica, "sans-serif";

// bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
